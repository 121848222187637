import { React, useState, useEffect, useCallback } from 'react';
import axiosConfig from '../axiosConfig';
import Header from '../Header/Header';
import MainMenu from '../MainMenu/MainMenu';
import Select from 'react-select';

const DeviceHealth = () => {
    const [queryRawData, setQueryRawData] = useState("");
    const [queryViewLogs, setQueryViewLogs] = useState("");
    const [allBoxes, setAllBoxes] = useState([]);
    const [deviceBoxID, setDeviceBoxID] = useState(null);
    const [loadingAvailableBoxes, setLoadingAvailableBoxes] = useState(false);
    const [deviceBoxInfo, setDeviceBoxInfo] = useState(null);
    const [loadingDeviceBoxInfo, setLoadingDeviceBoxInfo] = useState(false);
    const [errorMsgBoxInfo, setErrorMsgBoxInfo] = useState("Please select a valid Box ID");
    const [deviceRawData, setDeviceRawData] = useState(null);
    const [loadingDeviceRawData, setLoadingDeviceRawData] = useState(false);
    const [errorMsgDeviceRawData, setErrorMsgDeviceRawData] = useState("Please select a valid Box ID");
    const [deviceViewLogs, setDeviceViewLogs] = useState(null);
    const [loadingDeviceViewLogs, setLoadingDeviceViewLogs] = useState(false);
    const [errorMsgDeviceViewLogs, setErrorMsgDeviceViewLogs] = useState("Please select a valid Box ID and connect it to a Household if not already connected.");

    // Fetch available boxes on component mount
    useEffect(() => {
        setLoadingAvailableBoxes(false);
        axiosConfig.get("/device/available-boxes").then((rsp) => {
            setAllBoxes(rsp.data);
            setLoadingAvailableBoxes(true);
        }).catch((err) => {
            if (err.response.status === 422) {
                // handle error
            }
        });
    }, []);

    // useCallback for fetchDeviceBoxInfo to ensure it's stable and doesn't change on every render
    const fetchDeviceBoxInfo = useCallback(() => {
        if (deviceBoxID) {
            axiosConfig.get("/deviceboxinfo/" + deviceBoxID).then((rsp) => {
                setDeviceBoxInfo(rsp.data.device_box_info);
                setLoadingDeviceBoxInfo(true);
                setErrorMsgBoxInfo(null);
            }).catch((err) => {
                setLoadingDeviceBoxInfo(true);
                if (err.response.status === 414) {
                    setErrorMsgBoxInfo(err.response.data.error.device_box_id);
                } else if (err.response.status === 415) {
                    setErrorMsgBoxInfo(err.response.data.error);
                } else {
                    setErrorMsgBoxInfo("Invalid operation, Please try again");
                }
            });
        }
    }, [deviceBoxID]);

    // useCallback for fetchDeviceRawData to ensure it's stable and doesn't change on every render
    const fetchDeviceRawData = useCallback(() => {
        if (deviceBoxID) {
            axiosConfig.get("/devicerawdata/" + deviceBoxID).then((rsp) => {
                setDeviceRawData(rsp.data.device_raw_data);
                setLoadingDeviceRawData(true);
                setErrorMsgDeviceRawData(null);
                console.log(rsp.data.device_raw_data);
            }).catch((err) => {
                setLoadingDeviceRawData(true);
                if (err.response.status === 414) {
                    setErrorMsgDeviceRawData(err.response.data.error.device_box_id);
                } else if (err.response.status === 415) {
                    setErrorMsgDeviceRawData(err.response.data.error);
                } else {
                    setErrorMsgDeviceRawData("Invalid operation, Please try again");
                }
            });
        }
    }, [deviceBoxID]);


    // useCallback for fetchDeviceViewLogs to ensure it's stable and doesn't change on every render
    const fetchDeviceViewLogs = useCallback(() => {
        if (deviceBoxID) {
            axiosConfig.get("/deviceviewlogs/" + deviceBoxID).then((rsp) => {
                setDeviceViewLogs(rsp.data.device_view_logs);
                setLoadingDeviceViewLogs(true);
                setErrorMsgDeviceViewLogs(null);
                console.log(rsp.data.device_view_logs);
            }).catch((err) => {
                setLoadingDeviceViewLogs(true);
                if (err.response.status === 414) {
                    setErrorMsgDeviceViewLogs(err.response.data.error.device_box_id);
                } else if (err.response.status === 415) {
                    setErrorMsgDeviceViewLogs(err.response.data.error);
                } else {
                    setErrorMsgDeviceViewLogs("Invalid operation, Please try again");
                }
            });
        }
    }, [deviceBoxID]);

    // useEffect to fetch Device Box Info every 10 seconds
    useEffect(() => {
        if (deviceBoxID) {
            setLoadingDeviceBoxInfo(false);
            fetchDeviceBoxInfo(); // Fetch immediately
            const boxInfoInterval = setInterval(fetchDeviceBoxInfo, 10000); // 10 seconds (10,000 ms)
            return () => clearInterval(boxInfoInterval); // Cleanup interval
        }
    }, [deviceBoxID, fetchDeviceBoxInfo]);

    // useEffect to fetch Device Raw Data every 5 seconds
    useEffect(() => {
        if (deviceBoxID) {
            setLoadingDeviceRawData(false);
            fetchDeviceRawData(); // Fetch immediately
            const rawDataInterval = setInterval(fetchDeviceRawData, 5000); // 5 seconds (5,000 ms)
            return () => clearInterval(rawDataInterval); // Cleanup interval
        }
    }, [deviceBoxID, fetchDeviceRawData]);

    // useEffect to fetch Device View Logs every 5 seconds
    useEffect(() => {
        if (deviceBoxID) {
            setLoadingDeviceViewLogs(false);
            fetchDeviceViewLogs(); // Fetch immediately
            const viewLogsInterval = setInterval(fetchDeviceViewLogs, 5000); // 5 seconds (5,000 ms)
            return () => clearInterval(viewLogsInterval); // Cleanup interval
        }
    }, [deviceBoxID, fetchDeviceViewLogs]);

    const SearchRawData = (data) => {
        return data.filter(
            (item) =>
                item.channel_name.toLowerCase().includes(queryRawData.toLowerCase())
        );
    };

    const SearchViewLogs = (data) => {
        return data.filter(
            (item) =>
                item.channel_name.toLowerCase().includes(queryViewLogs.toLowerCase())
        );
    };


    return (
        <div><Header title="Device Health" />
            <MainMenu menu="devicehealth" />
            <div class="app-content content" style={{ backgroundColor: "azure", minHeight: "36em" }} >
                <div class="content-overlay"></div>
                <div class="content-wrapper">
                    <div class="content-header row">
                    </div>
                    <div class="content-body">



                        {/* Loading state */}
                        {loadingAvailableBoxes ? (


                            <><div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-content collapse show">
                                            <div className="card-body card-dashboard">
                                                <div className="table-responsive" style={{ border: "none" }}>
                                                    <div className="row align-items-center" style={{ margin: "0" }}>
                                                        {/* Device Box ID Section */}
                                                        <div className="col-12 col-md-8">
                                                            <div className="d-flex align-items-center mb-2" style={{ position: 'relative' }}>
                                                                <label
                                                                    htmlFor="device"
                                                                    style={{
                                                                        marginRight: "10px",
                                                                        fontWeight: "bold",
                                                                        fontSize: "18px"
                                                                    }}
                                                                >
                                                                    Device Box ID:
                                                                </label>
                                                                <div class="col-md-3">
                                                                    <Select
                                                                        placeholder="Select Box ID"
                                                                        options={allBoxes.map(box => ({ label: box.id, value: box.id }))}
                                                                        onChange={opt => setDeviceBoxID(opt.value)}
                                                                        value={allBoxes.find(box => box.id === deviceBoxID) ? { label: deviceBoxID, value: deviceBoxID } : null} // Update value handling
                                                                        menuPortalTarget={document.body} // Render the menu in a portal
                                                                        styles={{
                                                                            control: (provided) => ({
                                                                                ...provided,
                                                                                zIndex: 2, // Ensure the Select component has a higher z-index
                                                                            }),
                                                                            menu: (provided) => ({
                                                                                ...provided,
                                                                                zIndex: 2, // Ensure the menu has a higher z-index
                                                                            }),
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Button Section */}
                                                        <div className="col-12 col-md-4 text-md-end">
                                                            <button className="btn btn-primary">
                                                                Connect to Test Household
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                                {/*Loading & Conditionally render based on errorMsgBoxInfo */}
                                {errorMsgBoxInfo === null ? (
                                    loadingDeviceBoxInfo ? (

                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card">

                                                    <div class="card-content collapse show">
                                                        <div class="card-body card-dashboard">

                                                            <div class="row">
                                                                <div class="col-md-7"><h4 class="card-title">Device Box Info</h4></div>
                                                                <div class="col-md-5"></div>

                                                            </div>

                                                            <div class="table-responsive" >
                                                                <table class="table display nowrap table-striped table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Box ID</th>
                                                                            <th>Last Active</th>
                                                                            <th>Household</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        <tr>
                                                                            <td>{deviceBoxInfo.id}</td>
                                                                            <td>
                                                                                {deviceBoxInfo.last_request ? (
                                                                                    deviceBoxInfo.last_request
                                                                                ) : (
                                                                                    <span className="text-danger">ডিভাইস কখনো ডাটা পাঠায়নি</span>
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                {deviceBoxInfo.device_id ? (
                                                                                    <> <span className="text-success">হাউজ-হোল্ডে সংযুক্ত হয়েছে </span> {"\u2705"}</>
                                                                                ) : (
                                                                                    <span className="text-danger">হাউজ-হোল্ড সংযুক্ত নয়</span>
                                                                                )}
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                    ) : (
                                        <div className="card">
                                            <div className="card-header">
                                                <div class="card-title">Device Box Info</div>
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" className="rounded mx-auto d-block" alt="Loading" />
                                            </div>
                                        </div>

                                    )

                                ) : (
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">Device Box Info</h4>
                                            <h4><span class="danger">{errorMsgBoxInfo}</span></h4>
                                        </div>
                                    </div>
                                )}





                                {errorMsgDeviceRawData === null ? (
                                    loadingDeviceRawData ? (

                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card">

                                                    <div class="card-content collapse show">
                                                        <div class="card-body card-dashboard">

                                                            <div class="row">
                                                                <div class="col-md-7"><h4 class="card-title">Device Raw Data</h4></div>
                                                                <div class="col-md-5"><input type="text" class="search form-control round border-primary mb-1" placeholder="Search With Channel" onChange={e => setQueryRawData(e.target.value)} />
                                                                </div>

                                                            </div>

                                                            <div class="table-responsive" style={{ maxHeight: '400px', minHeight: '400px' }}>
                                                                <table class="table display nowrap table-striped table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Channel</th>
                                                                            <th>Server Receive Time</th>
                                                                            <th>Device ID</th>
                                                                            <th>Start Time</th>
                                                                            <th>End Time</th>
                                                                            <th>People</th>
                                                                            <th>Data Sequence</th>
                                                                            <th>Track ID</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {SearchRawData(deviceRawData).map((rawData) =>
                                                                            <tr>
                                                                                <td>
                                                                                    <div style={{ whiteSpace: 'nowrap' }}>
                                                                                        <img class="img-fluid" alt="" style={{ maxWidth: "3rem" }} src={"../../channels/logos/" + rawData.logo} />
                                                                                        {rawData.channel_name}
                                                                                    </div>
                                                                                </td>
                                                                                <td>{rawData.server_time}</td>
                                                                                <td>{rawData.device_id}</td>
                                                                                <td>{rawData.start}</td>
                                                                                <td>{rawData.finish}</td>
                                                                                <td>{rawData.people}</td>
                                                                                <td>{rawData.offline}</td>
                                                                                <td>{rawData.id}</td>
                                                                            </tr>

                                                                        )}

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>



                                    ) : (
                                        <div className="card">
                                            <div className="card-header">
                                                <div class="card-title">Device Raw Data</div>
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" className="rounded mx-auto d-block" alt="Loading" />
                                            </div>
                                        </div>

                                    )

                                ) : (
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">Device Raw Data</h4>
                                            <h4><span class="danger">{errorMsgDeviceRawData}</span></h4>
                                        </div>
                                    </div>
                                )}




                                {errorMsgDeviceViewLogs === null ? (
                                    loadingDeviceViewLogs ? (

                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card">

                                                    <div class="card-content collapse show">
                                                        <div class="card-body card-dashboard">

                                                            <div class="row">
                                                                <div class="col-md-7"><h4 class="card-title">Device View Logs</h4></div>
                                                                <div class="col-md-5"><input type="text" class="search form-control round border-primary mb-1" placeholder="Search With Channel" onChange={e => setQueryViewLogs(e.target.value)} />
                                                                </div>

                                                            </div>

                                                            <div class="table-responsive" style={{ maxHeight: '400px', minHeight: '400px' }}>
                                                                <table class="table display nowrap table-striped table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Channel</th>
                                                                            <th>Start Time</th>
                                                                            <th>Duration</th>
                                                                            <th>Device Box ID</th>
                                                                            <th>Household</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {SearchViewLogs(deviceViewLogs).map((viewLogs) =>
                                                                            <tr>
                                                                                <td>
                                                                                    <div style={{ whiteSpace: 'nowrap' }}>
                                                                                        <img class="img-fluid" alt="" style={{ maxWidth: "3rem" }} src={"../../channels/logos/" + viewLogs.logo} />
                                                                                        {viewLogs.channel_name}
                                                                                    </div>
                                                                                </td>
                                                                                <td>{viewLogs.started_watching_at}</td>
                                                                                <td>{viewLogs.duration}</td>
                                                                                <td>{viewLogs.box_id}</td>
                                                                                <td>{viewLogs.household_name}</td>
                                                                            </tr>

                                                                        )}

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>



                                    ) : (
                                        <div className="card">
                                            <div className="card-header">
                                                <div class="card-title">Device View Logs</div>
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" className="rounded mx-auto d-block" alt="Loading" />
                                            </div>
                                        </div>

                                    )

                                ) : (
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">Device View Logs</h4>
                                            <h4><span class="danger">{errorMsgDeviceViewLogs}</span></h4>
                                        </div>
                                    </div>
                                )}
                            </>




                        ) : (
                            <div className="card">
                                <div className="card-header">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/c/c7/Loading_2.gif?20170503175831" className="rounded mx-auto d-block" alt="Loading" />
                                </div>
                            </div>
                        )}






<br />
<br />


                    </div>
                </div>

            </div>
        </div>


    )
}
export default DeviceHealth;